import { useTranslation } from 'next-i18next';
import { Flex, SimpleGrid, Stack, Link, Box } from '@chakra-ui/react';
import { colors } from '@/styles/theme/colors';
import { H2 } from '@/components/Headings';
import { TextDefault } from '@/components/Texts';
import { outerLinks } from '@/utils/constants/outer-links';
import Image from 'next/image';

const GetApp = () => {
  const { t } = useTranslation('main');

  return (
    <Stack spacing={2} align={'center'} w='full' px={4}>
      <Flex
        bg={colors.gradients.lightBlue}
        direction={{ base: 'column', md: 'row' }}
        justify={'center'}
        align={'end'}
        gap={8}
        w={'full'}
        maxW={'8xl'}
        position={'relative'}
        overflow={'hidden'}
      >
        <Stack w='full' align={'center'} justify={'center'}>
          <SimpleGrid
            w={'full'}
            maxW={'7xl'}
            columns={{ base: 1, md: 2 }}
            justifyItems={'center'}
            gap={{ base: 4, md: 8 }}
            px={8}
          >
            <Stack
              display={{ base: 'none', md: 'flex' }}
              justify={'end'}
              align={'center'}
              maxW={{ base: 'sm', md: 400 }}
              py={2}
            >
              <Image
                width={866}
                height={1080}
                src={`/images/assets/png-files/diment-app.webp`}
                alt='Diment App'
              />
            </Stack>

            <Stack
              order={{ base: 1, md: 2 }}
              align={{ base: 'center', md: 'start' }}
              justify={'center'}
              w={'full'}
              maxW={'lg'}
              spacing={8}
              textAlign={{ base: 'center', lg: 'start' }}
              pt={{ base: 8, md: 16 }}
              pb={{ base: 8, md: 16 }}
            >
              <Stack
                w={'full'}
                spacing={4}
                align={{ base: 'center', lg: 'start' }}
              >
                <H2 color={colors.diment.darkBlue}>
                  {t('common.diment-app.heading')}
                </H2>
                <Flex
                  w={'full'}
                  maxW={{ base: 200, md: 'full' }}
                  justify={{ base: 'center', lg: 'start' }}
                >
                  <TextDefault fontWeight={500} color={colors.diment.darkBlue}>
                    {t('common.diment-app.desc')}
                  </TextDefault>
                </Flex>
              </Stack>
              <Stack
                display={{ base: 'flex', md: 'none' }}
                justify={'end'}
                align={'center'}
                maxW={{ base: 'sm', md: 'full' }}
              >
                <Image
                  width={400}
                  height={499}
                  src={`/images/assets/png-files/diment-app.webp`}
                  alt='Diment App'
                />
              </Stack>
              <Flex w={'full'} justify={{ base: 'center', lg: 'left' }} gap={4}>
                <Box maxW={150} filter='grayscale(100%)'>
                  <Image
                    src={`/images/home/appstore_soon.webp`}
                    alt={'apple'}
                    width={256}
                    height={75}
                  />
                </Box>
                <Box maxW={150}>
                  <Link href={outerLinks.mobileApp.googlePlay} target='_blank'>
                    <Image
                      src={`/images/home/google-play-badge.webp`}
                      alt={'google play'}
                      width={256}
                      height={76}
                    />
                  </Link>
                </Box>
              </Flex>
            </Stack>
          </SimpleGrid>
        </Stack>
      </Flex>
    </Stack>
  );
};

export default GetApp;
